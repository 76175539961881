<template>
  <div class="flex flex-col">
    <SeoMetaEditorial :page="page" />
    <PageBuilder :blocks="page?.blocks" :globals="globals" />
    <EditorialNotFound v-if="!response?.success || error" />
    <EditorialEditButton :id="page?.id" type-handle="homepage" />
  </div>
</template>
<script setup lang="ts">
definePageMeta({
  layout: 'full-width',
});

interface ApiResponse {
  success: boolean;
  page?: any;
  globals?: any;
}

const page = ref();
const globals = ref();
const { data, error } = await useFetch(`/ecom-api/editorial/get-page-by-slug`, {
  method: 'POST',
  body: JSON.stringify({
    slug: 'homepage',
    regionCode: useRegionCode(),
    type: 'homepage',
  }),
});

const response = data.value as ApiResponse;
page.value = response?.page;
globals.value = response?.globals;
</script>
